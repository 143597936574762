
#root, .App{
    width: 100vw !important;
    height: 100%;
    margin: 0;
    padding: 0;
}

header{
    width: 100vw !important;
}

@media screen and (min-width: 1024px){
    .opacity-30{
        opacity: 1;
    }
}
