@import '../../static/scss/fonts/fonts';
@import '../../static/scss/abstract/colors';
@import '../../static/scss/abstract/functions';
@import '../../static/scss/abstract/buttons';

.block {
  display: block !important;
}

nav {
  background-color: transparent;
  position: relative;

  .navbrand {
    width: 2rem;
    //height: 50px;
    position: absolute;
    left: 2rem;
    top: 1.5rem;
    cursor: pointer;
    //background-color: white;
    svg {
      .st0 {
        fill: #FFFFFF;
      }
    }
  }

  .btn-open {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 1rem;
    display: block;
    cursor: pointer;

    &:hover {
      svg {
        fill: getColor(darkBlue);
      }
    }
  }

  .btn-close {
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
    display: none;
    z-index: 2000000;

    svg {
      width: 36px;
      height: 36px;

      .st0 {
        fill: getColor(primaryBlue);
        transition: all ease-out 0.3s;
      }

      .st1 {
        fill: getColor(primaryWhite);
      }

      &:hover {
        .st0 {
          fill: getColor(hoverBtnColor);
          transition: all 0.6s ease-out;
        }
      }
    }
  }

  .w-80 {
    width: 80% !important;
  }

  .navbar-main {
    position: fixed;
    right: 0;
    height: 100vh;
    width: 0%; //80%
    transition: all 0.6s ease-out;
    background-color: getColor(primaryWhite);
    z-index: 20000;

    .navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;

      li {
        margin-bottom: 2rem;
        font-weight: normal;
        font-size: 19px;
        margin-right: 2.5rem;

        a {
          color: getColor(primaryBlue);
          font-weight: normal;
          font-size: 16px;
          //transition: all 0.6s ease-out;
        }
      }

      .primary {
        background-color: getColor(primaryBlue);
        border: 2px solid getColor(primaryBlue);
        border-radius: 34px;
        backdrop-filter: blur(4px);
        padding: 7.5px 2rem;
        transition: all 4s ease-out;

        &:hover {
          background-color: getColor(darkBlue);
        }

        a {
          color: getColor(primaryWhite);
        }
      }
    }
  }
}

@media only screen and (min-height: 420px) {
  nav .navbar-main .navbar-nav li {
    margin-bottom: 3rem;
  }
}

.width-0 {
  width: 0 !important;
  padding: 0 !important;
  visibility: hidden !important;
}

ul {
  li {
    opacity: 1;
    transition: 3s all !important;
  }
}

.visibility-hidden {
  ul {
    li {
      opacity: 0;
      transition: 0.2s all !important;
    }
  }
}


@media only screen and (min-width: 1024px) {
  header {
    position: absolute;
    top: 2rem;
    width: 100%;
    height: 5.1215rem;

    nav {
      position: relative;
      height: 5.1215rem;
      max-width: 90%;
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: space-between !important;
      align-items: center;

      .navbrand {
        position: static;
        width: 2.8rem;
        height: auto;
      }

      .btn-close {
        display: none !important;
      }

      .btn-open {
        display: none !important;
      }

      .navbar-main {
        width: auto;
        background-color: transparent;
        position: static !important;
        height: auto;
        align-items: center;
        .navbar-nav {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: auto;
          justify-content: flex-end !important;
          li {
            margin-bottom: 0;

            a {
              color: getColor(primaryWhite);
              font-size: 16px;

              &:hover {
                color: #bdcff2;
              }
            }

          }

          .primary {
            background-color: getColor(primaryBlue);
            border: 2px solid getColor(primaryWhite);
            backdrop-filter: blur(4px);
            font-size: 16px;

            &:hover {
              background-color: getColor(primaryWhite);
              color: getColor(primaryBlue);
            }
          }
        }
      }
    }
  }
  .visibility-hidden {
    ul {
      li {
        opacity: 1;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  header {
    nav {
      .navbrand {
        width: 4rem;
      }

      .navbar-main {
        .navbar-nav {
          li {
            a {
              font-size: 19px;
            }
          }

          .primary {
            width: 178px;
            height: 56px;
            font-size: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

#button{
  a{
    color: white;
  }
}
