@import './colors';
@import './functions';

.btn {
    border-radius: 34px;
    backdrop-filter: blur(4px);
    padding: 7.5px 2rem;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    /* or 534% */
    cursor: pointer;
    text-transform: uppercase;
    transition: all 0.6s ease-out;
}

.btn-primary {
    @extend .btn;
    border: 2px solid getColor(primaryBlue);
    background-color: getColor(primaryBlue);
    color: getColor(primaryWhite);
    &:hover{
        border: 2px solid getColor(hoverBtnColor);
        background-color: getColor(hoverBtnColor);
        transition: all 0.3s ease-out;
    }
}