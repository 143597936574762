/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "EurostileBold";
  src: url("../../fonts/Eurostile/fonts/EurostileBold.eot"); /* IE9 Compat Modes */
  src: url("../../fonts/Eurostile/fonts/EurostileBold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("../../fonts/Eurostile/fonts/EurostileBold.otf") format("opentype"), /* Open Type Font */
    url("../../fonts/Eurostile/fonts/EurostileBold.svg") format("svg"), /* Legacy iOS */
    url("../../fonts/Eurostile/fonts/EurostileBold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../../fonts/Eurostile/fonts/EurostileBold.woff") format("woff"), /* Modern Browsers */
    url("../../fonts/Eurostile/fonts/EurostileBold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
