@import './static/scss/fonts/fonts';
@import './static/scss/abstract/colors';
@import './static/scss/abstract/functions';

* {
  box-sizing: border-box;
}

.opacity-30 {
  opacity: 0.3;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

p {
  line-height: 24px;
  font-size: 12px;
  color: getColor(silver);
}

.icon-img {
  width: 97px;
}

a {
  color: getColor(primaryWhite);
}

body {
  position: relative;
}

.mt-2 {
  margin-top: 2rem;
}

button {
  &:focus,
  &:active {
    outline: none;
  }
}
